.content {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 1.5rem;
  width: 100%;
  height: 100%;
}

.content > :not(:first-child) {
  margin-left: 2rem;
}

@media screen and (max-width: 768px) {
  .content > :not(:first-child) {
    margin-left: inherit;
    margin-top: 2rem;
  }
}

.content_profile {
  display: flex;
  flex-direction: column;

  flex: 1 0;
}

.content_profile > :not(:first-child) {
  margin-top: 1rem;
}

/* Row on mobile */
@media (max-width: 768px) {
  .content {
    flex-direction: column;
  }
  .content_profile {
    align-items: center;
  }

  .content_profile_image {
    width: 50% !important;
  }

  .content_profile_name,
  .content_profile_title {
    text-align: center;
  }
}

.content_profile_name {
  font-size: 1.7rem;
  font-weight: 600;
  line-height: 2.5rem;
}

.content_profile_image {
  border-radius: 50%;
  width: 60%;
}

.content_profile_title {
  color: var(--theme);
}

.content_profile_title_link {
  --text: var(--theme);
}

.content_description {
  flex: 2 0;
  display: flex;
  flex-direction: column;
}

.content_description > :not(:first-child) {
  margin-top: 2rem;
}

@media screen and (max-width: 768px) {
  .content_description {
    margin-top: 2rem;
  }
}

.content_description_text {
  flex: 2 0;
  background: rgba(118, 118, 118, 0.5);
  height: 100%;
  padding: 1em;

  display: flex;
  flex-direction: column;
  line-height: 1.5rem;
  position: relative;
}

.content_description_text p {
  margin-bottom: 2rem;
  color: white;
}

.content_description_text p:last-child {
  margin-bottom: 0;
  margin-top: auto;
}

.content_description_viewmore {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  color: var(--text);
}

.content_description_viewmore svg {
  vertical-align: middle;
}

.content_description_viewmore_link {
}

.content_description_viewmore_link_text {
  margin-right: 1.5rem;
}
